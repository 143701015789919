// Utility function to format currency values
const formatCurrency = (value: any) => `$${value !== null ? parseFloat(value).toFixed(2) : '0.00'}`;
const formatCurrencyInt = (value: any) => `$${value !== null ? parseInt(value) : '0'}`;
const formatPercentage = (value: any) => `${value !== null ? parseFloat(value).toFixed(1) : '0.0'}%`;

const expensedwithzeroattendees_column_set = [
    { Header: 'Charged AU', accessor: 'charged_au' },
    { Header: 'EID', accessor: 'eid' },
    { Header: 'Employee', accessor: 'employee' },
    { Header: 'Paid Date', accessor: 'paid_date' },
    { Header: 'Entry Key', accessor: 'entry_key' },
    { Header: 'Expense Type', accessor: 'expense_type' },
    { Header: 'Transaction Date', accessor: 'transaction_date' },
    { Header: 'City/Location', accessor: 'city_location' },
    { Header: 'State/Province/Region', accessor: 'state_province_region' },
    { Header: 'City and State', accessor: 'city_and_state' },
    { Header: 'Country', accessor: 'country' },
    { Header: 'Approved Amount', accessor: 'approved_amount' },
    { Header: 'Number of Attendees', accessor: 'number_of_attendees' },
    { Header: 'Cost per Attendee', accessor: 'cost_per_attendee' },
    { Header: 'Anomaly Description', accessor: () => `Number of attendees = 0` },
    { Header: 'Level 2', accessor: 'level2' },
    { Header: 'Level 3', accessor: 'level3' },
    { Header: 'Level 4', accessor: 'level4' },
    { Header: 'Level 5', accessor: 'level5' },
    { Header: 'Level 6', accessor: 'level6' },
    { Header: 'Level 7', accessor: 'level7' },
    { Header: 'Level 8', accessor: 'level8' },
    { Header: 'Level 9', accessor: 'level9' },
    { Header: 'Level 10', accessor: 'level10' },
    { Header: 'Level 11', accessor: 'level11' },
    { Header: 'Level 12', accessor: 'level12' },
    { Header: 'Level 13', accessor: 'level13' },
    { Header: 'Level 14', accessor: 'level14' },
    { Header: 'Level 15', accessor: 'level15' },
    { Header: 'GhostCard', accessor: 'ghost_card' },
    { Header: 'Planner', accessor: 'planner' }
]

const overindividualcap_column_set = [
    { Header: 'Charged AU', accessor: 'charged_au' },
    { Header: 'EID', accessor: 'eid' },
    { Header: 'Employee', accessor: 'employee' },
    { Header: 'Paid Date', accessor: 'paid_date' },
    { Header: 'Expense Type', accessor: 'expense_type' },
    { Header: 'Transaction Date', accessor: 'transaction_date' },
    { Header: 'Approved Amount', accessor: 'approved_amount' },
    { Header: 'Area Diff', accessor: 'area_diff' },
    { Header: 'Meal Cap', accessor: 'meal_cap' },
    { Header: 'Savings Opportunity', accessor: 'lost_savings' },
    { Header: 'Level 2', accessor: 'level2' },
    { Header: 'Level 3', accessor: 'level3' },
    { Header: 'Level 4', accessor: 'level4' },
    { Header: 'Level 5', accessor: 'level5' },
    { Header: 'Level 6', accessor: 'level6' },
    { Header: 'Level 7', accessor: 'level7' },
    { Header: 'Level 8', accessor: 'level8' },
    { Header: 'Level 9', accessor: 'level9' },
    { Header: 'Level 10', accessor: 'level10' },
    { Header: 'Level 11', accessor: 'level11' },
    { Header: 'Level 12', accessor: 'level12' },
    { Header: 'Level 13', accessor: 'level13' },
    { Header: 'Level 14', accessor: 'level14' },
    { Header: 'Level 15', accessor: 'level15' }
]

const overmultiattendeecap_column_set = [
    { Header: 'Charged AU', accessor: 'charged_au' },
    { Header: 'EID', accessor: 'eid' },
    { Header: 'Employee', accessor: 'employee' },
    { Header: 'Paid Date', accessor: 'paid_date' },
    { Header: 'Entry Key', accessor: 'entry_key' },
    { Header: 'Expense Type', accessor: 'expense_type' },
    { Header: 'Transaction Date', accessor: 'transaction_date' },
    { Header: 'City/Location', accessor: 'city_location' },
    { Header: 'State/Province/Region', accessor: 'state_province_region' },
    { Header: 'City and State', accessor: 'city_and_state' },
    { Header: 'Country', accessor: 'country' },
    { Header: 'Approved Amount', accessor: 'approved_amount' },
    { Header: 'Number of Attendees', accessor: 'number_of_attendees' },
    { Header: 'Cost per Attendee', accessor: 'cost_per_attendee' },
    { Header: 'Area Diff', accessor: 'area_diff' },
    { Header: 'Meal Cap', accessor: 'meal_cap' },
    { Header: 'Savings Opportunity', accessor: 'lost_savings' },
    { Header: 'Level 2', accessor: 'level2' },
    { Header: 'Level 3', accessor: 'level3' },
    { Header: 'Level 4', accessor: 'level4' },
    { Header: 'Level 5', accessor: 'level5' },
    { Header: 'Level 6', accessor: 'level6' },
    { Header: 'Level 7', accessor: 'level7' },
    { Header: 'Level 8', accessor: 'level8' },
    { Header: 'Level 9', accessor: 'level9' },
    { Header: 'Level 10', accessor: 'level10' },
    { Header: 'Level 11', accessor: 'level11' },
    { Header: 'Level 12', accessor: 'level12' },
    { Header: 'Level 13', accessor: 'level13' },
    { Header: 'Level 14', accessor: 'level14' },
    { Header: 'Level 15', accessor: 'level15' },
    { Header: 'Comments', accessor: 'comment' }
]

const bookingoutsideagencydata_column_set = [
    { Header: 'EID', accessor: 'eid' },
    { Header: 'Employee Name', accessor: 'employee_name' },
    { Header: 'Employee Email', accessor: 'employee_email_address' },
    { Header: 'VIP', accessor: 'vip' },
    { Header: 'AU', accessor: 'entity_au' },
    { Header: 'Level 2', accessor: 'level2' },
    { Header: 'Level 3', accessor: 'level3' },
    { Header: 'Level 4', accessor: 'level4' },
    { Header: 'Level 5', accessor: 'level5' },
    { Header: 'Level 6', accessor: 'level6' },
    { Header: 'Level 7', accessor: 'level7' },
    { Header: 'Level 8', accessor: 'level8' },
    { Header: 'Level 9', accessor: 'level9' },
    { Header: 'Level 10', accessor: 'level10' },
    { Header: 'Level 11', accessor: 'level11' },
    { Header: 'Level 12', accessor: 'level12' },
    { Header: 'Level 13', accessor: 'level13' },
    { Header: 'Level 14', accessor: 'level14' },
    { Header: 'Level 15', accessor: 'level15' },
    { Header: 'Vendor', accessor: 'vendor' },
    { Header: 'Expense Type', accessor: 'expense_type' },
    { Header: 'Paid Date', accessor: 'paid_date' },
    { Header: 'Entry Key', accessor: 'entry_key' },
    { Header: 'Ticket Number', accessor: 'ticket_number' },
    { Header: 'Expense Amount', accessor: 'expense_amount' },
    { Header: 'Approved Amount', accessor: 'approved_amount' },
    { Header: 'Business Purpose', accessor: 'businesspurpose_description' }
]

const cardatafile_column_set = [
    { Header: 'EID', accessor: 'employee_id' },
    { Header: 'Passenger Last Name/First Name', accessor: 'traveler_name' },
    { Header: 'AU', accessor: 'au_number' },
    { Header: 'Level 2', accessor: 'level2' },
    { Header: 'Level 3', accessor: 'level3' },
    { Header: 'Level 4', accessor: 'level4' },
    { Header: 'Level 5', accessor: 'level5' },
    { Header: 'Level 6', accessor: 'level6' },
    { Header: 'Level 7', accessor: 'level7' },
    { Header: 'Level 8', accessor: 'level8' },
    { Header: 'Level 9', accessor: 'level9' },
    { Header: 'Level 10', accessor: 'level10' },
    { Header: 'Level 11', accessor: 'level11' },
    { Header: 'Level 12', accessor: 'level12' },
    { Header: 'Level 13', accessor: 'level13' },
    { Header: 'Level 14', accessor: 'level14' },
    { Header: 'Level 15', accessor: 'level15' },
    { Header: 'Car Company Vendor', accessor: 'car_rental_vendor' },
    { Header: 'Pickup Date', accessor: 'pickup_date' },
    { Header: 'Drop-Off Date', accessor: 'drop_off_date' },
    { Header: 'Rental Days', accessor: 'rental_days' },
    { Header: 'Daily Rate', accessor: 'car_rental_daily_rate' },
    { Header: 'Car Total Cost', accessor: 'car_total_cost' },
    { Header: 'CarSize', accessor: 'carsize' },
    { Header: 'Reason for not booking preferred car size/company', accessor: 'short_car_category' },
    { Header: 'City Name', accessor: 'car_pickup_city_name' },
    { Header: 'State', accessor: 'car_pickup_state_cd' },
    { Header: 'Preferred/Non-Preferred Car Company', accessor: 'preferred_car_category' },
    { Header: 'Online', accessor: 'online_offline' },
    { Header: 'Title', accessor: 'title_of_traveler' },
    { Header: 'Requested By', accessor: 'booker_name' },
    { Header: 'PNR', accessor: 'pnr' }
]

const hoteldatafile_column_set = [
    { Header: 'EID', accessor: 'employee_id' },
    { Header: 'Passenger Last Name/First Name', accessor: 'traveler_name' },
    { Header: 'AU', accessor: 'au_number' },
    { Header: 'Level 2', accessor: 'level2' },
    { Header: 'Level 3', accessor: 'level3' },
    { Header: 'Level 4', accessor: 'level4' },
    { Header: 'Level 5', accessor: 'level5' },
    { Header: 'Level 6', accessor: 'level6' },
    { Header: 'Level 7', accessor: 'level7' },
    { Header: 'Level 8', accessor: 'level8' },
    { Header: 'Level 9', accessor: 'level9' },
    { Header: 'Level 10', accessor: 'level10' },
    { Header: 'Level 11', accessor: 'level11' },
    { Header: 'Level 12', accessor: 'level12' },
    { Header: 'Level 13', accessor: 'level13' },
    { Header: 'Level 14', accessor: 'level14' },
    { Header: 'Level 15', accessor: 'level15' },
    { Header: 'Hotel Name', accessor: 'hotel_name' },
    { Header: 'Hotel City', accessor: 'hotel_city_name' },
    { Header: 'Hotel State', accessor: 'hotel_state_cd' },
    { Header: 'Reasons for Booking Above Hotel City Cap', accessor: 'short_hotel_category' },
    { Header: 'Check In Date', accessor: 'check_in_date' },
    { Header: 'Check Out Date', accessor: 'check_out_date' },
    { Header: 'Room Nights', accessor: 'hotel_roomnights' },
    { Header: 'Room Rate', accessor: 'hotel_room_rate' },
    { Header: 'Hotel Total Cost', accessor: 'hotel_bookedamount' },
    { Header: 'Hotel Savings Opportunity', accessor: 'hotel_lost_savings_over_cap' },
    { Header: 'Preferred/Non-Preferred Hotel', accessor: 'preferred_hotel_category' },
    { Header: 'Online', accessor: 'online_offline' },
    { Header: 'Title', accessor: 'title_of_traveler' },
    { Header: 'Requested By', accessor: 'booker_name' },
    { Header: 'CapCity', accessor: 'hotel_city_names_cap' },
    { Header: 'Hotel Cap', accessor: 'final_hotel_cap_rates' },
    { Header: 'PNR', accessor: 'pnr' },
    { Header: 'Approver', accessor: 'approved_by' }
]

const airdata_cux_column_set = [
    { Header: 'EID', accessor: 'employee_id' },
    { Header: 'Traveller', accessor: 'traveler_name' },
    { Header: 'Country POS', accessor: 'country' },
    { Header: 'D/I', accessor: 'dom_int' },
    { Header: 'AU', accessor: 'au_number' },
    { Header: 'Level 2', accessor: 'level2' },
    { Header: 'Level 3', accessor: 'level3' },
    { Header: 'Level 4', accessor: 'level4' },
    { Header: 'Level 5', accessor: 'level5' },
    { Header: 'Level 6', accessor: 'level6' },
    { Header: 'Level 7', accessor: 'level7' },
    { Header: 'Level 8', accessor: 'level8' },
    { Header: 'Level 9', accessor: 'level9' },
    { Header: 'Level 10', accessor: 'level10' },
    { Header: 'Level 11', accessor: 'level11' },
    { Header: 'Level 12', accessor: 'level12' },
    { Header: 'Level 13', accessor: 'level13' },
    { Header: 'Level 14', accessor: 'level14' },
    { Header: 'Level 15', accessor: 'level15' },
    { Header: 'Tickets', accessor: 'air_net_tickets' },
    { Header: 'Fare Paid', accessor: 'fare_paid' },
    { Header: 'Low fare', accessor: 'low_fare' },
    { Header: 'Savings Opportunity', accessor: 'lost_savings_tickets_gt_100' },
    { Header: 'Ticket Reasons', accessor: 'short_air_category' },
    { Header: 'Airline', accessor: 'carrier_validating_name' },
    { Header: 'Routing', accessor: 'ctyrtg' },
    { Header: 'Origin City Code', accessor: 'origin_city_code' },
    { Header: 'Origin City Name', accessor: 'origin_city_name' },
    { Header: 'Dest City Code', accessor: 'dest_city_code' },
    { Header: 'Dest City Name', accessor: 'dest_city_name' },
    { Header: 'Start Date', accessor: 'firstdpartdate' },
    { Header: 'End Date', accessor: 'lastarvldate' },
    { Header: 'Trip Duration', accessor: 'trip_duration' },
    { Header: 'Advance Purchase Days', accessor: 'advance_purchase_days' },
    { Header: 'cosrtg_descr', accessor: 'cosrtg_descr' },
    { Header: 'Online', accessor: 'online_offline' },
    { Header: 'Title', accessor: 'title_of_traveler' },
    { Header: 'Requested By', accessor: 'booker_name' },
    { Header: 'PNR', accessor: 'pnr' },
    { Header: 'Business Purpose', accessor: 'businesspurpose_description' },
    { Header: 'Manager Email', accessor: 'manager_email' },
    { Header: 'Approver', accessor: 'approver' }
]

const airdata_lt14daysadv_column_set = [
    { Header: 'EID', accessor: 'employee_id' },
    { Header: 'Traveller', accessor: 'traveler_name' },
    { Header: 'Country POS', accessor: 'country' },
    { Header: 'D/I', accessor: 'dom_int' },
    { Header: 'AU', accessor: 'au_number' },
    { Header: 'Level 2', accessor: 'level2' },
    { Header: 'Level 3', accessor: 'level3' },
    { Header: 'Level 4', accessor: 'level4' },
    { Header: 'Level 5', accessor: 'level5' },
    { Header: 'Level 6', accessor: 'level6' },
    { Header: 'Level 7', accessor: 'level7' },
    { Header: 'Level 8', accessor: 'level8' },
    { Header: 'Level 9', accessor: 'level9' },
    { Header: 'Level 10', accessor: 'level10' },
    { Header: 'Level 11', accessor: 'level11' },
    { Header: 'Level 12', accessor: 'level12' },
    { Header: 'Level 13', accessor: 'level13' },
    { Header: 'Level 14', accessor: 'level14' },
    { Header: 'Level 15', accessor: 'level15' },
    { Header: 'Fare Paid', accessor: 'fare_paid' },
    { Header: 'Low fare', accessor: 'low_fare' },
    { Header: 'Savings Opportunity', accessor: 'lost_savings_tickets_gt_100' },
    { Header: 'Ticket Reasons', accessor: 'short_air_category' },
    { Header: 'Airline', accessor: 'carrier_validating_name' },
    { Header: 'Routing', accessor: 'ctyrtg' },
    { Header: 'Start Date', accessor: 'firstdpartdate' },
    { Header: 'End Date', accessor: 'lastarvldate' },
    { Header: 'Advance Purchase Days', accessor: 'advance_purchase_days' },
    { Header: 'cosrtg_descr', accessor: 'cosrtg_descr' },
    { Header: 'Online', accessor: 'online_offline' },
    { Header: 'Title', accessor: 'title_of_traveler' },
    { Header: 'Requested By', accessor: 'booker_name' },
    { Header: 'PNR', accessor: 'pnr' },
    { Header: 'Business Purpose', accessor: 'businesspurpose_description' }
]

const airdata_over100lostsavings_column_set = [
    { Header: 'EID', accessor: 'employee_id' },
    { Header: 'Traveller', accessor: 'traveler_name' },
    { Header: 'Country POS', accessor: 'country' },
    { Header: 'D/I', accessor: 'dom_int' },
    { Header: 'AU', accessor: 'au_number' },
    { Header: 'Level 2', accessor: 'level2' },
    { Header: 'Level 3', accessor: 'level3' },
    { Header: 'Level 4', accessor: 'level4' },
    { Header: 'Level 5', accessor: 'level5' },
    { Header: 'Level 6', accessor: 'level6' },
    { Header: 'Level 7', accessor: 'level7' },
    { Header: 'Level 8', accessor: 'level8' },
    { Header: 'Level 9', accessor: 'level9' },
    { Header: 'Level 10', accessor: 'level10' },
    { Header: 'Level 11', accessor: 'level11' },
    { Header: 'Level 12', accessor: 'level12' },
    { Header: 'Level 13', accessor: 'level13' },
    { Header: 'Level 14', accessor: 'level14' },
    { Header: 'Level 15', accessor: 'level15' },
    { Header: 'Fare Paid', accessor: 'fare_paid' },
    { Header: 'Low fare', accessor: 'low_fare' },
    { Header: 'Savings Opportunity', accessor: 'lost_savings_tickets_gt_100' },
    { Header: 'Ticket Reasons', accessor: 'short_air_category' },
    { Header: 'Airline', accessor: 'carrier_validating_name' },
    { Header: 'Routing', accessor: 'ctyrtg' },
    { Header: 'Start Date', accessor: 'firstdpartdate' },
    { Header: 'End Date', accessor: 'lastarvldate' },
    { Header: 'Advance Purchase Days', accessor: 'advance_purchase_days' },
    { Header: 'cosrtg_descr', accessor: 'cosrtg_descr' },
    { Header: 'Online', accessor: 'online_offline' },
    { Header: 'Title', accessor: 'title_of_traveler' },
    { Header: 'Requested By', accessor: 'booker_name' },
    { Header: 'PNR', accessor: 'pnr' },
    { Header: 'Business Purpose', accessor: 'businesspurpose_description' },
    { Header: 'Approver', accessor: (row: any) => row.approver || row.approved_by }
]

const travelandexpensesummary_column_set = [
    { Header: 'Status', accessor: 'status' },
    { Header: 'EID', accessor: 'employee_id' },
    { Header: 'Name', accessor: 'traveler_name' },
    { Header: 'Job Title', accessor: 'job_title' },
    { Header: 'Active Employee Email Address', accessor: 'emp_email_id' },
    { Header: 'Travel Booked By Email Address', accessor: 'booker_name' },
    { Header: 'AU', accessor: 'au_number' },
    { Header: 'Description 2', accessor: 'description2' },
    { Header: 'Description 3', accessor: 'description3' },
    { Header: 'Description 4', accessor: 'description4' },
    { Header: 'Description 5', accessor: 'description5' },
    { Header: 'Description 6', accessor: 'description6' },
    { Header: 'Description 7', accessor: 'description7' },
    { Header: 'Description 8', accessor: 'description8' },
    { Header: 'Description 9', accessor: 'description9' },
    { Header: 'Description 10', accessor: 'description10' },
    { Header: 'Description 11', accessor: 'description11' },
    { Header: 'Description 12', accessor: 'description12' },
    { Header: 'Description 13', accessor: 'description13' },
    { Header: 'Description 14', accessor: 'description14' },
    { Header: 'Description 15', accessor: 'description15' },
    { Header: 'Tickets Adv Pur < 14 Days Current Month', accessor: 'tickets_adv_pur_lt_14_days' },
    { Header: 'Airline Savings Opportunity > $100 Current Month', accessor: (row: any) => formatCurrency(row.lost_savings_tickets_gt_100) },
    { Header: 'Airline Savings Opportunity Tickets > $100 Current Month', accessor: 'airline_lost_tickets_gt_100' },
    { Header: 'Tickets Current Month', accessor: 'air_net_tickets' },
    { Header: 'Count of Exchanges Current Month', accessor: 'exchange_tickets_air' },
    { Header: 'Cost of Exchanges Current Month', accessor: (row: any) => formatCurrency(row.exchange_ticket_air_cost) },
    { Header: 'Air/Rail Spend Current Month', accessor: (row: any) => formatCurrency(row.air_rail_spend) },
    { Header: 'Hotel Nights Over Cap Current Month', accessor: 'hotel_above_over_cap_nights' },
    { Header: 'Hotel Savings Opportunity Current Month', accessor: (row: any) => formatCurrency(row.hotel_lost_savings_over_cap) },
    { Header: 'Hotel Nights Current Month', accessor: 'hotel_roomnights' },
    { Header: 'Hotel Spend Current Month', accessor: (row: any) => formatCurrency(row.hotel_bookedamount) },
    { Header: 'Car Rental Days Current Month', accessor: 'car_days' },
    { Header: 'Car Rental Spend Current Month', accessor: (row: any) => formatCurrency(row.car_bookedamount) },
    { Header: 'Count of Expensed Entries Meals Current Month', accessor: 'entry_key_count' },
    { Header: 'Meals Spend Current Month', accessor: (row: any) => formatCurrency(row.approved_amount_meals) },
    { Header: 'Individual Meals Over Cap Expensed Entries Current Month', accessor: 'individual_meals_over_cap_expensed_entries' },
    { Header: 'Individual Meals Over Cap Savings Opportunity Current Month', accessor: (row: any) => formatCurrency(row.individual_meals_over_cap_lost_savings) },
    { Header: 'Customer facing Multi-Attendee Meals Over Cap Expensed Entries Current Month', accessor: 'cf_ma_meals_over_cap_expensed_entries' },
    { Header: 'Customer facing Multi-Attendee Meals Savings Opportunity Current Month', accessor: (row: any) => formatCurrency(row.cf_ma_meals_lost_savings) },
    { Header: 'Non-Customer facing Multi-Attendee Meals Over Cap Expensed Entries Current Month', accessor: 'ncf_ma_meals_over_cap_expensed_entries' },
    { Header: 'Non-Customer facing Multi-Attendee Meals Savings Opportunity Current Month', accessor: (row: any) => formatCurrency(row.ncf_ma_meals_lost_savings) },
    { Header: 'Personal Mileage Expensed Current Month', accessor: 'personal_mileage_expensed' },
    { Header: 'Personal Miles Spend Current Month', accessor: (row: any) => formatCurrency(row.personal_miles_spend) },
    { Header: 'Total T&E Spend Current Month', accessor: (row: any) => formatCurrency(row.total) },
]

const travelandentertainmentspend_column_set = [
    { Header: 'Rank', accessor: 'rank' },
    { Header: 'EID', accessor: 'employee_id' },
    { Header: 'Name', accessor: 'traveler_name' },
    { Header: 'Job Title', accessor: 'job_title' },
    { Header: 'AU', accessor: 'au_number' },
    { Header: 'Description 2', accessor: 'description2' },
    { Header: 'Description 3', accessor: 'description3' },
    { Header: 'Description 4', accessor: 'description4' },
    { Header: 'Description 5', accessor: 'description5' },
    { Header: 'Description 6', accessor: 'description6' },
    { Header: 'Description 7', accessor: 'description7' },
    { Header: 'Description 8', accessor: 'description8' },
    { Header: 'Description 9', accessor: 'description9' },
    { Header: 'Description 10', accessor: 'description10' },
    { Header: 'Description 11', accessor: 'description11' },
    { Header: 'Description 12', accessor: 'description12' },
    { Header: 'Description 13', accessor: 'description13' },
    { Header: 'Description 14', accessor: 'description14' },
    { Header: 'Description 15', accessor: 'description15' },
    { Header: 'Air/Rail Spend Current Month', accessor: 'air_rail_spend' },
    { Header: 'Hotel Spend Current Month', accessor: 'hotel_bookedamount' },
    { Header: 'Car Rental Spend Current Month', accessor: 'car_bookedamount' },
    { Header: 'Meals Spend Current Month', accessor: 'approved_amount_meals' },
    { Header: 'Personal Miles Spend Current Month', accessor: 'personal_miles_spend' },
    { Header: 'Total T&E Spend Current Month', accessor: 'total' },
]

const businessandfirstclasssegments_column_set = [
    { Header: 'Issue Date', accessor: 'issue_date' },
    { Header: 'AU', accessor: 'au_number' },
    { Header: 'CityPair', accessor: 'citypair' },
    { Header: 'Miles', accessor: 'miles' },
    { Header: 'Amount', accessor: (row: any) => formatCurrencyInt(row.amount_with_tax) },
    { Header: 'Carrier', accessor: 'carrier' },
    { Header: 'ClassCode', accessor: 'classcode' },
    { Header: 'Class', accessor: 'class' },
    { Header: 'PNR', accessor: 'pnr' },
    { Header: 'Ticket', accessor: 'ticket' },
    { Header: 'Dom/Intl', accessor: 'dom_int' },
    { Header: 'Invoice Type', accessor: 'invoice_type' },
    { Header: 'EID', accessor: 'employee_id' },
    { Header: 'Traveller', accessor: 'traveler_name' },
    { Header: 'Title', accessor: 'title_of_traveler' },
    { Header: 'Depart Date', accessor: 'departure_date' },
    { Header: 'Arrive Date', accessor: 'arrival_date' },
    { Header: 'Level 2', accessor: 'level2' },
    { Header: 'Level 3', accessor: 'level3' },
    { Header: 'Level 4', accessor: 'level4' },
    { Header: 'Level 5', accessor: 'level5' },
    { Header: 'Level 6', accessor: 'level6' },
    { Header: 'Level 7', accessor: 'level7' },
    { Header: 'Level 8', accessor: 'level8' },
    { Header: 'Level 9', accessor: 'level9' },
    { Header: 'Level 10', accessor: 'level10' },
    { Header: 'Level 11', accessor: 'level11' },
    { Header: 'Level 12', accessor: 'level12' },
    { Header: 'Level 13', accessor: 'level13' },
    { Header: 'Level 14', accessor: 'level14' },
    { Header: 'Level 15', accessor: 'level15' },
    { Header: 'Approver', accessor: (row: any) => row.approval || row.approver }
]


const top100bycategory_column_set = [
    { Header: 'Rank', accessor: 'rank' },
    { Header: 'EID', accessor: 'eid' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Title', accessor: 'title' },
    { Header: 'Air/Rail Spend', accessor: (row: any) => formatCurrencyInt(row.air_rail_spend) },
    { Header: 'Meals Spend', accessor: (row: any) => formatCurrencyInt(row.mealsspend) },
    { Header: 'Personal Vehicle Mileage Spend', accessor: (row: any) => formatCurrencyInt(row.personal_veh_mileagespend) },
    { Header: 'Hotel Spend', accessor: (row: any) => formatCurrencyInt(row.hotelspend) },
    { Header: 'Car Rental Spend', accessor: (row: any) => formatCurrencyInt(row.carspend) },
    { Header: 'Total Spend', accessor: (row: any) => formatCurrencyInt(row.totalspend) },
    { Header: 'Cost of Airline Lost Savings > $100', accessor: (row: any) => formatCurrencyInt(row.airline_lost_saving_gt_100) },
    { Header: '% of Air/Rail Lost Savings versus Total Air/Rail Spend', accessor: (row: any) => formatPercentage(row.prcnt_ar_lost_saving_vs_total_ar_spend) },
    { Header: 'Lost Savings of Employee Travel Meals Over Meal Caps', accessor: (row: any) => formatCurrencyInt(row.lostsavings_etmeals_overcap) },
    { Header: 'Cost of Hotel Lost Savings over Hotel City Caps', accessor: (row: any) => formatCurrencyInt(row.hotel_lost_savings_over_cap) },
    { Header: 'Total Non Compliance Lost Savings', accessor: (row: any) => formatCurrencyInt(row.total_noncompliance_lost_savings) },
    { Header: 'Current YTD Total Spend', accessor: (row: any) => formatCurrencyInt(row.currentytd_totalspend) },
    { Header: 'Previous YTD Total Spend', accessor: (row: any) => formatCurrencyInt(row.previousytd_totalspend) },
    { Header: 'H/(L) vs Prior Yr', accessor: (row: any) => formatCurrencyInt(row.hl_vs_prior_yr) },
    { Header: 'Meeting and Event Planner', accessor: 'meetings_events_planner' },
]

const top100bybusinesspurpose_column_set = [
    { Header: 'Rank', accessor: 'rank' },
    { Header: 'EID', accessor: 'eid' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Title', accessor: 'title' },
    { Header: 'Customer Facing', accessor: (row: any) => formatCurrencyInt(row.customer_facing) },
    { Header: 'Formal Recognition Events', accessor: (row: any) => formatCurrencyInt(row.formal_recognition_events) },
    { Header: 'Internal Business Operations', accessor: (row: any) => formatCurrencyInt(row.internal_business_operations) },
    { Header: 'Recruiting', accessor: (row: any) => formatCurrencyInt(row.recruiting) },
    { Header: 'Team Member Development', accessor: (row: any) => formatCurrencyInt(row.team_member_development) },
    { Header: 'Other Integration', accessor: (row: any) => formatCurrencyInt(row.other_integration) },
    { Header: 'Total', accessor: (row: any) => formatCurrencyInt(row.totalspend) },
    { Header: 'Current YTD Total Spend', accessor: (row: any) => formatCurrencyInt(row.currentytd_totalspend) },
    { Header: 'Previous YTD Total Spend', accessor: (row: any) => formatCurrencyInt(row.previousytd_totalspend) },
    { Header: 'H/(L) vs Prior Yr', accessor: (row: any) => formatCurrencyInt(row.hl_vs_prior_yr) },
    { Header: 'Meeting and Event Planner', accessor: 'meetings_events_planner' },
]

const noncompliancesummary_column_set = [
    { Header: 'Level', accessor: 'level' },
    { Header: 'Country', accessor: 'country' },
    { Header: 'Air Tickets Advanced Purchased < 14 Days (Rail Excluded)', accessor: 'tickets_adv_pur_lt_14_days' },
    { Header: 'Total Air/Rail Tickets', accessor: 'total_airrail_tickets' },
    { Header: '% Air Tickets Advance Purchased  < 14 Days (Rail Excluded)', accessor: 'air_ticket_lt_14_perc' },
    { Header: 'Cost of Airline Savings Opportunity > $100 (Rail Excluded)', accessor: 'lost_savings_tickets_gt_100' },
    { Header: 'Savings Opportunity > $100 as a % Total Air/Rail Spend', accessor: 'saving_air_rail_perc' },
    { Header: 'Total Air/Rail Spend', accessor: 'total_air_rail_spend' },
    { Header: 'Average Price per Air/Rail Ticket', accessor: 'avg_air_rail_ticket' },
    { Header: 'Hotel Nights Over City Cap', accessor: 'hotel_nights_over_city_cap' },
    { Header: 'Total Hotel Nights', accessor: 'total_hotel_roomnights' },
    { Header: '% Hotel Nights Over City Cap', accessor: 'hotel_nights_over_city_cap_perc' },
    { Header: 'Cost of Hotel Savings Opportunity over City Caps', accessor: 'hotel_lost_savings_over_cap' },
    { Header: 'Total Hotel Spend', accessor: 'total_hotel_spend' },
    { Header: 'Hotel Savings Opportunity as a % of Total Hotel Spend', accessor: 'hotel_savings_as_total_nights_perc' },
    { Header: 'Average Rate per Hotel Night', accessor: 'avg_rate_per_hotel' },
    { Header: 'Car Rental Days', accessor: 'car_rental_days' },
    { Header: 'Total Car Rental Spend', accessor: 'total_car_rental_spend' },
    { Header: 'Average Daily Rate per Car Rental', accessor: 'avg_car_rental_spend' },
    { Header: 'Expensed Meal Entries', accessor: 'expensed_meal_entries' },
    { Header: 'Total Meal Spend', accessor: 'total_meal_spend' },
    { Header: 'Expensed Employee Travel Meals (Single Attendee)', accessor: 'exp_trav_meal_entries_single' },
    { Header: 'Cost of Expensed Employee Travel Meals (Single Attendee)', accessor: 'cost_exp_trav_meal_entries_single' },
    { Header: '% of Employee Travel Meals Over Meal Cap (Area 0,1) ($100)', accessor: 'emp_trav_overcap100_a01_perc' },
    { Header: 'Cost of Employee Travel Meals Over Meal Cap (Area 0,1) ($100)', accessor: 'cost_emp_trav_overcap100_a01' },
    { Header: '% of Employee Travel Meals Over Meal Cap (Area 2,3,4) ($75)', accessor: 'emp_trav_overcap75_a234_perc' },
    { Header: 'Cost of Employee Travel Meals Over Meal Cap (Area 2,3,4) ($75)', accessor: 'cost_emp_trav_overcap75_a234' },
    { Header: 'Personal Vehicle Miles Expensed', accessor: 'personal_miles_expensed' },
    { Header: 'Personal Vehicle Miles Expensed Spend', accessor: 'personal_miles_spend' },
    { Header: 'Total Air/Rail Spend Customer Facing', accessor: 'air_rail_spend_ecf' },
    { Header: 'Total Hotel Spend Customer Facing', accessor: 'hotel_spend_ecf' },
    { Header: 'Total Car Rental Spend Customer Facing', accessor: 'car_rental_spend_ecf' },
    { Header: 'Total Meals Spend Customer Facing', accessor: 'meals_spend_ecf' },
    { Header: 'Total Personal Vehicle Miles Spend Customer Facing', accessor: 'personal_miles_spend_ecf' },
    { Header: 'Total Spend Customer Facing', accessor: 'total_spend_ecf' },
    { Header: 'Air/Rail Customer Facing', accessor: 'air_rail_perc' },
    { Header: 'Hotel Customer Facing', accessor: 'hotel_perc' },
    { Header: 'Car Rental Customer Facing', accessor: 'car_rental_perc' },
    { Header: 'Meals Customer Facing', accessor: 'meals_perc' },
    { Header: 'Personal Vehicle Mileage Customer Facing', accessor: 'personal_vehicle_mileage_perc' },
]

const summarybyorg_column_set = [
    { Header: 'LOB', accessor: 'description2' },
    { Header: 'LOB Lower Level', accessor: 'description3' },
    { Header: 'Customer Facing (YTD)', accessor: (row: any) => formatCurrencyInt(row.customer_facing_ytd) },
    { Header: 'Non-Customer Facing (YTD)', accessor: (row: any) => formatCurrencyInt(row.noncustomer_facing_ytd) },
    { Header: 'Total (YTD)', accessor: (row: any) => formatCurrencyInt(row.total_ytd) },
    { Header: 'Customer Facing (Previous YTD)', accessor: (row: any) => formatCurrencyInt(row.customer_facing_pytd) },
    { Header: 'Non-Customer Facing (Previous YTD)', accessor: (row: any) => formatCurrencyInt(row.noncustomer_facing_pytd) },
    { Header: 'Total (Previous YTD)', accessor: (row: any) => formatCurrencyInt(row.total_pytd) },
    { Header: 'Customer Facing Spend Variance', accessor: (row: any) => formatCurrencyInt(row.customer_facing_var) },
    { Header: 'Customer Facing Variance %', accessor: (row: any) => formatPercentage(row.customer_facing_var_prct) },
    { Header: 'Non-Customer Facing Spend Variance', accessor: (row: any) => formatCurrencyInt(row.noncustomer_facing_var) },
    { Header: 'Non-Customer Facing Variance %', accessor: (row: any) => formatPercentage(row.noncustomer_facing_var_prct) },
    { Header: 'Total Spend Variance', accessor: (row: any) => formatCurrencyInt(row.total_var) },
    { Header: 'Total Variance %', accessor: (row: any) => formatPercentage(row.total_var_prct) },
]

const outofpolicy_column_set = [
    { Header: 'Traveler', accessor: 'traveler' },
    { Header: 'PNR', accessor: 'pnr' },
    { Header: 'PCC', accessor: 'pcc' },
    { Header: 'DK', accessor: 'dk' },
    { Header: 'Flight Info', accessor: 'flight_info' },
    { Header: 'Depart Time', accessor: 'depart_time' },
    { Header: 'Depart City', accessor: 'depart_city' },
    { Header: 'Arrival Time', accessor: 'arrival_time' },
    { Header: 'Arrival City', accessor: 'arrival_city' },
    { Header: 'RC', accessor: 'rc' },
    { Header: 'Reason Code Description', accessor: 'reason_code_description' },
]

const personaldailybookings_column_set = [
    { Header: 'TM Name', accessor: 'tm_name' },
    { Header: 'EID', accessor: 'eid' },
    { Header: 'AU', accessor: 'au' },
    { Header: 'Locator', accessor: 'locator' },
    { Header: 'Booking Date', accessor: 'booking_date' },
    { Header: 'Carrier', accessor: 'carrier' },
    { Header: 'Flight', accessor: 'flight' },
    { Header: 'Class of Service', accessor: 'class_of_service' },
    { Header: 'Depart Date', accessor: 'depart_date' },
    { Header: 'Depart City', accessor: 'depart_city' },
    { Header: 'Arrival Date', accessor: 'arrival_date' },
    { Header: 'Arrival City', accessor: 'arrival_city' },
    { Header: 'Purpose of Trip', accessor: 'purpose_of_trip' },
]

const threeormoreviptravellersonsameflight_column_set = [
    { Header: 'Ticketed?', accessor: 'ticketed' },
    { Header: 'Customer', accessor: 'customer' },
    { Header: 'Locator', accessor: 'locator' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'VIP Traveler', accessor: 'vip_traveler' },
    { Header: 'Class', accessor: 'class' },
    { Header: 'Booking Date', accessor: 'booking_date' },
    { Header: 'VIP', accessor: 'vip' },
]

const vipreport_column_set = [
    { Header: 'Traveler', accessor: 'traveler' },
    { Header: 'PNR', accessor: 'pnr' },
    { Header: 'PCC', accessor: 'pcc' },
    { Header: 'DK', accessor: 'dk' },
    { Header: 'Flight Info', accessor: 'flight_info' },
    { Header: 'Depart Time', accessor: 'depart_time' },
    { Header: 'Depart City', accessor: 'depart_city' },
    { Header: 'Arrival Time', accessor: 'arrival_time' },
    { Header: 'Arrival City', accessor: 'arrival_city' },
]

const octravelersnext21daysair_column_set = [
    { Header: 'Traveler', accessor: 'traveler' },
    { Header: 'PNR', accessor: 'pnr' },
    { Header: 'Emp ID', accessor: 'eid' },
    { Header: 'VIP', accessor: 'vip' },
    { Header: 'Type', accessor: 'type' },
    { Header: 'Airline - Flt #', accessor: 'airline' },
    { Header: 'Start City', accessor: 'start_city' },
    { Header: 'End City', accessor: 'end_city' },
    { Header: 'Dept Date', accessor: 'dept_date' },
    { Header: 'Dept Time', accessor: 'dept_time' },
    { Header: 'Arv Date', accessor: 'arv_date' },
    { Header: 'Arv Time', accessor: 'arv_time' },
]

const octravelersnext21dayshotel_column_set = [
    { Header: 'Traveler', accessor: 'traveler' },
    { Header: 'PNR', accessor: 'pnr' },
    { Header: 'Emp ID', accessor: 'eid' },
    { Header: 'VIP', accessor: 'vip' },
    { Header: 'Type', accessor: 'type' },
    { Header: 'Hotel Property', accessor: 'hotel_property' },
    { Header: 'Hotel Address', accessor: 'hotel_address' },
    { Header: 'Hotel City, ST, Zip', accessor: 'hotel_city_st_zip' },
    { Header: 'Check in Date', accessor: 'check_in_date' },
    { Header: 'Check out Date', accessor: 'check_out_date' },
]

const airdirectionalreport_column_set = [
    { Header: 'Origin Code', accessor: 'orig_code' },
    { Header: 'Origin City Name', accessor: 'orig_city_name' },
    { Header: 'Origin ST', accessor: 'origin_state' },
    { Header: 'Orig', accessor: 'origin_ctry' },
    { Header: 'Orig Metro', accessor: 'origin_metro' },
    { Header: 'Orig Metro Name', accessor: 'origin_metro_name' },
    { Header: 'Orig Metro ST', accessor: 'origin_metro_st' },
    { Header: 'Orig Metro Ctry', accessor: 'orign_metro_ctry' },
    { Header: 'Dest Code', accessor: 'dest_code' },
    { Header: 'Dest City Name', accessor: 'dest_city_name' },
    { Header: 'Dest ST', accessor: 'dest_state' },
    { Header: 'Dest Ctry', accessor: 'dest_ctry' },
    { Header: 'Dest Metro Code', accessor: 'dest_metro_cd' },
    { Header: 'Dest Metro Name', accessor: 'dest_metro_name' },
    { Header: 'Dest Metro ST', accessor: 'dest_metro_st' },
    { Header: 'Dest Metro Ctry', accessor: 'dest_metro_ctry' },
    { Header: 'Segments', accessor: 'segment' },
    { Header: 'Segment Amount', accessor: 'segment_amount' },
    { Header: 'City-Pair (Orig-Dest)', accessor: 'city_pair_orig_dest' },
    { Header: 'Avg Segment', accessor: 'avg_segment' },
    { Header: 'Metro-Pair (Orig-Dest)', accessor: 'metro_pair_orig_dest' },
    { Header: 'Metro-Pair Segments', accessor: 'metro_pair_segment' },
    { Header: 'Metro-Pair Segment Amount', accessor: 'metro_pair_segment_amount' },
    { Header: 'Metro-Pair Avg Segment Amount', accessor: 'metro_pair_avg_segment_amount' }
]

const atickettotalsmonth_column_set = [
    { Header: 'LOB', accessor: 'lob' },
    { Header: 'TrxYear', accessor: 'trxyear' },
    { Header: 'TrxMon', accessor: 'trxmonth' },
    { Header: 'Tickets', accessor: 'tickets' }
]

const shortvslong_column_set = [
    { Header: 'LOB', accessor: 'lob' },
    { Header: 'Tickets', accessor: 'tickets' },
    { Header: 'Shortlong', accessor: 'shortlong' },
    { Header: 'CF', accessor: 'cf' },
    { Header: 'TG', accessor: 'tg' },
    { Header: 'Ticket Type', accessor: 'tickettype' },
    { Header: 'TrxYear', accessor: 'trxyear' },
    { Header: 'TrxMonth', accessor: 'trxmonth' },
    { Header: 'TrxYearMon', accessor: 'trxyearmon' },
    { Header: 'Month-Year', accessor: 'month_year' },
]

const car_chain_spd_column_set = [
    { Header: 'Chain Code', accessor: 'car_vendor_cd' },
    { Header: 'Chain', accessor: 'car_rental_vendor' },
    { Header: 'Days', accessor: 'days' },
    { Header: 'Spend', accessor: 'spend' },
    { Header: 'Avg Rate', accessor: 'avg_rate' },
    { Header: '#Bookings', accessor: 'bookings' },
    { Header: '%Total', accessor: '%total' },
]

const exchange_and_refund_column_set = [
    { Header: 'Trans Type', accessor: 'trans_type' },
    { Header: '#Tickets', accessor: 'tickets' },
    { Header: 'Amount', accessor: 'air_net_ticket_amount' },
    { Header: 'LOB%', accessor: 'perc_lob' },
    { Header: 'Month', accessor: 'year_month' },
    { Header: 'Line of Business', accessor: 'lob' },
]
const airline_spend_column_set = [
    { Header: 'Validated Carrier Code', accessor: 'validating_carrier_cd' },
    { Header: 'Validated Carrier Name', accessor: 'carrier_validating_name' },
    { Header: 'Gross Tickets Current', accessor: 'current_gross_tickets' },
    { Header: 'Gross Tickets YTD', accessor: 'ytd_gross_tickets' },
    { Header: 'Gross Amount Current', accessor: 'current_gross_amount' },
    { Header: 'Gross Amount YTD', accessor: 'ytd_gross_amount' },
    { Header: 'Net Tickets Current', accessor: 'current_air_net_tickets' },
    { Header: 'Net Tickets YTD', accessor: 'ytd_air_net_tickets' },
    { Header: 'Net Amount Current', accessor: 'current_air_net_ticket_amount' },
    { Header: 'Net Amount YTD', accessor: 'ytd_air_net_ticket_amount' },
    { Header: 'ATP Current', accessor: 'current_atp' },
    { Header: 'ATP YTD', accessor: 'ytd_atp' },
    { Header: '% of TTL', accessor: 'ttl' },
]
const airline_cont_saving_column_set = [
    { Header: 'Lob', accessor: 'lob' },
    { Header: '4M', accessor: '4m' },
    { Header: 'AA', accessor: 'aa' },
    { Header: 'AC', accessor: 'ac' },
    { Header: 'AF', accessor: 'af' },
    { Header: 'AS', accessor: 'as' },
    { Header: 'B6', accessor: 'b6' },
    { Header: 'BA', accessor: 'ba' },
    { Header: 'CX', accessor: 'cx' },
    { Header: 'DL', accessor: 'dl' },
    { Header: 'EK', accessor: 'ek' },
    { Header: 'EY', accessor: 'ey' },
    { Header: 'IB', accessor: 'ib' },
    { Header: 'JJ', accessor: 'jj' },
    { Header: 'JL', accessor: 'jl' },
    { Header: 'KL', accessor: 'kl' },
    { Header: 'LA', accessor: 'la' },
    { Header: 'LH', accessor: 'lh' },
    { Header: 'LX', accessor: 'lx' },
    { Header: 'NH', accessor: 'nh' },
    { Header: 'OS', accessor: 'os' },
    { Header: 'SN', accessor: 'sn' },
    { Header: 'SQ', accessor: 'sq' },
    { Header: 'UA', accessor: 'ua' },
    { Header: 'VS', accessor: 'vs' },
    { Header: 'WN', accessor: 'wn' },
]
const hotel_prop_rpt_column_set = [
    { Header: 'City Name', accessor: 'city_name' },
    { Header: 'State Code', accessor: 'state_code' },
    { Header: 'Chain Code', accessor: 'chain_code' },
    { Header: 'Hotel Brand', accessor: 'hotel_parent' },
    { Header: 'Property Name', accessor: 'property_name' },
    { Header: 'City Total', accessor: 'city_nights' },
    { Header: 'Curr Nights', accessor: 'curr_nights' },
    { Header: 'Curr Nights %', accessor: 'curr_night_perc' },
    { Header: 'YTD Nights', accessor: 'ytd_nights' },
    { Header: 'Curr Spend', accessor: 'curr_spend' },
    { Header: 'Curr Spend %', accessor: 'curr_spend_perc' },
    { Header: 'YTD Spend', accessor: 'ytd_spend' },
    { Header: 'ARR Curr', accessor: 'arr_curr' },
    { Header: 'ARR YTD', accessor: 'arr_ytd' },
    { Header: 'City Total YTD', accessor: 'city_nights_ytd' },
    { Header: 'Phone', accessor: 'hotel_phone' },
    { Header: 'Pref Property', accessor: 'prefproperty' },
    { Header: 'Street Address', accessor: 'hotel_address' },
]
const trans_summary_column_set = [
    { Header: 'DK', accessor: 'dk' },
    { Header: 'DK Type', accessor: 'dk_description' },
    { Header: 'Booking Type', accessor: 'booking_type' },
    { Header: 'DomIntl', accessor: 'dom_int' },
    { Header: 'Transactions', accessor: 'gross_count' },
    { Header: 'Amount', accessor: 'gross_amount' },
    { Header: 'Miles', accessor: 'miles' },
    { Header: 'Cost/Mile', accessor: 'cost_per_mile' },
]
const ey_air_column_set = [
    { Header: 'Traveler Name', accessor: 'traveler_name' },
    { Header: 'Emp ID', accessor: 'employee_id' },
    { Header: 'Employee Country', accessor: 'employee_country' },
    { Header: 'AU', accessor: 'au' },
    { Header: 'Booking Date', accessor: 'booking_date' },
    { Header: 'Record Locator', accessor: 'record_locator' },
    { Header: 'Ticket No', accessor: 'ticket' },
    { Header: 'Ticket Type', accessor: 'exchange_refund_description' },
    { Header: 'Segment No', accessor: 'segment_number' },
    { Header: 'Calculated Segment No', accessor: 'calculated_segmentno' },
    { Header: 'Origin Country Name', accessor: 'origin_country' },
    { Header: 'Origin Airport Code', accessor: 'origin_airport_code'},
    { Header: 'Destination Country Name', accessor: 'dest_country' },
    { Header: 'Destination Airport Code', accessor: 'destination_airport_code'},
    { Header: 'Routing Airports', accessor: 'routing_airports' },
    { Header: 'Departure Date', accessor: 'departure_date' },
    { Header: 'Departure Time', accessor: 'departure_time' },
    { Header: 'Arrival Date', accessor: 'arrival_date' },
    { Header: 'Arrival Time', accessor: 'arrival_time' },
    { Header: 'Cost Center', accessor: 'cost_center' },
    { Header: 'Description 2', accessor: 'description2' },
    { Header: 'Description 3', accessor: 'description3' },
    { Header: 'Description 4', accessor: 'description4' },
    { Header: 'Description 5', accessor: 'description5' },
    { Header: 'Description 6', accessor: 'description6' },
    { Header: 'Description 7', accessor: 'description7' },
    { Header: 'Description 8', accessor: 'description8' },
    { Header: 'Description 9', accessor: 'description9' },
    { Header: 'Description 10', accessor: 'description10' },
    { Header: 'Description 11', accessor: 'description11' },
    { Header: 'Description 12', accessor: 'description12' },
    { Header: 'Description 13', accessor: 'description13' },
    { Header: 'Description 14', accessor: 'description14' },
    { Header: 'Description 15', accessor: 'description15' },
]

const ey_rail_column_set = [
    { Header: 'Traveler Name', accessor: 'traveler_name' },
    { Header: 'Entity AU', accessor: 'entityAU' },
    { Header: 'Emp ID', accessor: 'employee_id' },
    { Header: 'AU', accessor: 'au' },
    { Header: 'Employee Country', accessor: 'employee_country' },
    { Header: 'Ticket Number', accessor: 'ticket' },
    { Header: 'Country Pair', accessor: 'origin_country_destination_country_cd' },
    { Header: 'Country Pair Name', accessor: 'org_ctry_dest_ctry_nm' },
    { Header: 'Station Pair', accessor: 'non_directional_market_pair_code' },
    { Header: 'Station Pair Name', accessor: 'non_directional_market_pair' },
    { Header: 'Departure Date', accessor: 'departure_date' },
    { Header: 'Cost Center', accessor: 'costcenter' },
    { Header: 'Description 2', accessor: 'description2' },
    { Header: 'Description 3', accessor: 'description3' },
    { Header: 'Description 4', accessor: 'description4' },
    { Header: 'Description 5', accessor: 'description5' },
    { Header: 'Description 6', accessor: 'description6' },
    { Header: 'Description 7', accessor: 'description7' },
    { Header: 'Description 8', accessor: 'description8' },
    { Header: 'Description 9', accessor: 'description9' },
    { Header: 'Description 10', accessor: 'description10' },
    { Header: 'Description 11', accessor: 'description11' },
    { Header: 'Description 12', accessor: 'description12' },
    { Header: 'Description 13', accessor: 'description13' },
    { Header: 'Description 14', accessor: 'description14' },
    { Header: 'Description 15', accessor: 'description15' },
    { Header: 'Home', accessor: 'home' },
    { Header: 'TicketType', accessor: 'exchange_refund_description' },
]

const ey_hotel_column_set = [
    { Header: 'Traveler Name', accessor: 'traveler_name' },
    { Header: 'EmpID', accessor: 'employee_id' },
    { Header: 'Employee Country', accessor: 'employee_country' },
    { Header: 'Booking Date', accessor: 'booking_dt' },
    { Header: 'Property', accessor: 'hotel_name' },
    { Header: 'Property City', accessor: 'hotel_city_name' },
    { Header: 'Property Country', accessor: 'hotel_country_name' },
    { Header: 'Arrival Date', accessor: 'check_in_date' },
    { Header: 'Room Nights', accessor: 'hotel_roomnights' },
    { Header: 'AU', accessor: 'au_number' },
    { Header: 'Description 2', accessor: 'description2' },
    { Header: 'Description 3', accessor: 'description3' },
    { Header: 'Description 4', accessor: 'description4' },
    { Header: 'Description 5', accessor: 'description5' },
    { Header: 'Description 6', accessor: 'description6' },
    { Header: 'Description 7', accessor: 'description7' },
    { Header: 'Description 8', accessor: 'description8' },
    { Header: 'Description 9', accessor: 'description9' },
    { Header: 'Description 10', accessor: 'description10' },
    { Header: 'Description 11', accessor: 'description11' },
    { Header: 'Description 12', accessor: 'description12' },
    { Header: 'Description 13', accessor: 'description13' },
    { Header: 'Description 14', accessor: 'description14' },
    { Header: 'Description 15', accessor: 'description15' },
]

const airline_opt_column_set = [
    { Header: 'Airline', accessor: 'airline' },
    { Header: 'Market Pair', accessor: 'market_pair' },
    { Header: 'Total Segments', accessor: 'segments' },
    { Header: 'Total Spend', accessor: 'total_spend' },
    { Header: 'Fare Basis', accessor: 'farebasis' },
    { Header: 'Dom/Int', accessor: 'dom_int' },
    { Header: 'Ctry-Ctry', accessor: 'ctry_ctry' },
    { Header: 'Pre Contract Fare Inc Tax', accessor: 'pre_contracted_fare' },
    { Header: 'Base Fare w/o Tax', accessor: 'base_fare_without_tax' },
    { Header: 'Taxes', accessor: 'taxes' }
]

const hotel_chain_spend_column_set = [
    {Header: 'Hotel', accessor: 'hotel_chain_no'},
    {Header: 'Parent Chain', accessor: 'hotel_parent_chain_name'},
    {Header: 'Chain', accessor: 'hotel_chain'},
    {Header: 'Nights', accessor: 'nights'},
    {Header: 'Spend', accessor: 'spend'},
    {Header: 'Avg/Night', accessor: 'avg'},
]
const glbl_trx_summary_column_set = [
    {Header: 'Booking Country', accessor: 'country'},
    {Header: 'Dom/Int', accessor: 'dom_int'},
    {Header: 'Online Count', accessor: 'online_count'},
    {Header: 'Online Amount', accessor: 'online_amount'},
    {Header: 'Online Cost/Mile', accessor: 'online_avg'},
    {Header: 'Traditional Count', accessor: 'traditional_count'},
    {Header: 'Traditional Amount', accessor: 'traditional_amount'},
    {Header: 'Traditional Cost/Mile', accessor: 'traditional_avg'},
    {Header: 'Total Count', accessor: 'total_count'},
    {Header: 'Total Amount', accessor: 'total_amount'},
    {Header: 'Total Cost/Mile', accessor: 'total_avg'},
    {Header: 'Online Count %', accessor: 'online_count_perc'},
    {Header: 'Traditional Count %', accessor: 'traditional_count_perc'},
]
const segment_mil_column_set = [
    {Header: 'WFC', accessor: 'class'},
    {Header: 'Miles Group', accessor: 'miles_group'},
    {Header: 'Segment Flights', accessor: 'total_seg'},
    {Header: 'Total Miles', accessor: 'total_miles'},
]
const top_trvl_seg_mil_qtr_column_set = [
    {Header: 'Last Name', accessor: 'last_name'},
    {Header: 'First Name', accessor: 'first_name'},
    {Header: 'EID', accessor: 'employee_id'},
    {Header: 'VIP', accessor: 'vip_tracker'},
    {Header: 'Carrier Type', accessor: 'carrier_cd'},
    {Header: 'Segments', accessor: 'total_seg'},
    {Header: 'Spend', accessor: 'total_spend'},
    {Header: 'Miles', accessor: 'total_miles'},
]
const top_trvl_seg_mil_yrly_column_set = [
    {Header: 'Last Name', accessor: 'last_name'},
    {Header: 'First Name', accessor: 'first_name'},
    {Header: 'EID', accessor: 'employee_id'},
    {Header: 'VIP', accessor: 'vip_tracker'},
    {Header: 'Carrier Type', accessor: 'carrier_cd'},
    {Header: 'Segments', accessor: 'total_seg'},
    {Header: 'Spend', accessor: 'total_spend'},
    {Header: 'Miles', accessor: 'total_miles'},
]
const glbl_air_spd_sumry_column_set = [
    {Header: 'Country', accessor: 'country'},
    {Header: 'Market', accessor: 'dom_int'},
    {Header: '#Tickets', accessor: 'total_tickets'},
    {Header: 'Net Spend', accessor: 'total_spend'},
    {Header: 'Spend Share', accessor: 'spend_perc'},
    {Header: 'Savings', accessor: 'total_savings'},
    {Header: 'Missed Savings', accessor: 'total_missed_savings'},
    {Header: 'Net ATP', accessor: 'net_atp'},
]

const nav_metric_column_set = [
    {Header: 'Date', accessor: 'date'},
    {Header: 'Day of Week', accessor: 'week_day'},
    {Header: '# Domestic Tickets Purchased', accessor: 'dom_purchase'},
    {Header: '# Domestic Exchanges', accessor: 'dom_exchange'},
    {Header: '# International Ticket Purchased', accessor: 'int_purchase'},
    {Header: '# International Exchanges', accessor: 'int_exchange'},
    {Header: 'Total # of Tickets Purchased (not incl Refunds)', accessor: 'total_tickets'},
    {Header: 'Total # Tickets Purchased (Excluding Exchanges and Refunds)', accessor: 'total_ticket_purchase'},
    {Header: 'Total Air/Rail Spend', accessor: 'total_spend'},
    {Header: 'Total Air/Rail Spend (Excluding Exchanges and Refunds)', accessor: 'total_exchange_spend'},
    {Header: '# of Non-Compliant Air', accessor: 'noncompliant_tkt'},
    {Header: 'Loss Associated Non-Compliant Air booked', accessor: 'loss_non_comp_tkt'},
    {Header: '% of Tickets booked Non-Compliant versus Total Tickets booked', accessor: 'perc_tkt_booked'},
    {Header: 'Non-Compliant Hotel Room Nights', accessor: 'noncompliant_htl_nights'},
    {Header: 'Loss Associated to Non-Compliant Hotel Room Nights booked', accessor: 'loss_non_comp_htl'},
    {Header: 'Total Hotel Room Nights', accessor: 'hotelroom_nights'},
    {Header: 'Total Hotel Spend', accessor: 'hotel_room_spend'},
    {Header: '% of Hotel Room Nights Non-Compliant versus Total Room Nights booked', accessor: 'perc_hotel_nights'},
    {Header: 'Car Rental Days (Car booked other than Standard/Compact/Intermediate)', accessor: 'noncompliant_car_days'},
    {Header: 'Car Rental Spend (Car booked other than Standard/Compact/Intemediate)', accessor: 'noncompliant_car_spend'},
    {Header: 'Total Car Rental Days', accessor: 'car_days'},
    {Header: 'Total Car Rental Spend', accessor: 'car_amount'},
    {Header: 'CarRentalDaysNonPreferred', accessor: 'nonpref_car_days'},
    {Header: 'CarRentalSpendNonPreferred', accessor: 'nonpref_car_spend'},
    {Header: '% of Car Rental Days with Non-Standard/Compact Rentals versus Total Rental Days booked', accessor: 'perc_car_days'},
]

export { 
    expensedwithzeroattendees_column_set, 
    overindividualcap_column_set, 
    overmultiattendeecap_column_set, 
    bookingoutsideagencydata_column_set, 
    cardatafile_column_set, 
    hoteldatafile_column_set, 
    airdata_cux_column_set, 
    airdata_lt14daysadv_column_set, 
    airdata_over100lostsavings_column_set, 
    travelandexpensesummary_column_set, 
    travelandentertainmentspend_column_set,
    businessandfirstclasssegments_column_set,
    top100bycategory_column_set,
    top100bybusinesspurpose_column_set, 
    summarybyorg_column_set,
    noncompliancesummary_column_set,
    outofpolicy_column_set,
    personaldailybookings_column_set,
    threeormoreviptravellersonsameflight_column_set,
    vipreport_column_set,
    octravelersnext21daysair_column_set,
    octravelersnext21dayshotel_column_set,
    airdirectionalreport_column_set,
    atickettotalsmonth_column_set,
    shortvslong_column_set,
    car_chain_spd_column_set,
    exchange_and_refund_column_set,
    airline_spend_column_set,
    airline_cont_saving_column_set,
    hotel_prop_rpt_column_set,
    trans_summary_column_set,
    ey_air_column_set,
    ey_rail_column_set,
    ey_hotel_column_set,
    airline_opt_column_set,
    hotel_chain_spend_column_set,
    glbl_trx_summary_column_set,
    segment_mil_column_set,
    top_trvl_seg_mil_qtr_column_set,
    top_trvl_seg_mil_yrly_column_set,
    glbl_air_spd_sumry_column_set,
    nav_metric_column_set
}